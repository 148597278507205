'use client';

import { buttonClasses as buttonUnstyledClasses } from '@mui/base/Button';

export const CIGRO_BUTTON_CLASSNAME = 'CigroButton';

export const BUTTON_CLASSNAME_ROOT_HOVER = '&:hover';

export const BUTTON_CLASSNAME_ROOT_FOCUS = `&.${buttonUnstyledClasses.focusVisible}`;

export const BUTTON_CLASSNAME_ROOT_ACTIVE = `&.${buttonUnstyledClasses.active}`;

export const BUTTON_CLASSNAME_ROOT_DISABLED = `&.${buttonUnstyledClasses.disabled}`;
